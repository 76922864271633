import { ACCESS_FLAGS } from 'constants/config';
import { MenuItem, PRODUCT_GROUPS } from 'types';
import { Section, StringMap } from 'types/kyb';

export const RECIPEINT_BANK_NAME = 'Bank name';
export const RECIPEINT_BANK_ROUTING_CODE = 'ABA / Routing number';
export const RECIPEINT_BANK_ACCOUNT_NUMBER = 'Account number';
export const RECIPEINT_BANK_ACCOUNT_HOLDER_NAME = 'Account holder name';
export const MEMORANDUM = 'Memorandum';
export const BANK_ADDRESS = 'Bank address';
export const BENEFICIARY_ADDRESS = 'Recipient address';
export const ACCOUNT_NUMBER = 'Account number';
export const ACCOUNT_NAME = 'Account name';

export enum ZeroBalanceWidgetType {
  Home = 'home',
  ACCOUNT = 'account',
  WITHOUT_BALANCE = 'withount_balance',
}

export const WITHDRAWAL_STEPS: Section[] = [
  // {
  //   key: 'transfer_details',
  //   title: 'Transfer Details'
  // },
  {
    key: 'withdrawal_amount',
    title: 'Withdrawal Amount',
  },
  {
    key: 'confirm_withdrawal',
    title: 'Confirm withdrawal',
  },
];

export const AccountDetailTypes = [
  { label: 'Inside Country', value: 'inside' },
  { label: 'Outside Country', value: 'outside' },
];

export const AccountsTabs: MenuItem[] = [
  { label: 'Banking', value: PRODUCT_GROUPS.banking, id: ACCESS_FLAGS.IS_PAYOUTS_ENABLED },
  { label: 'Treasury', value: PRODUCT_GROUPS.treasury, id: ACCESS_FLAGS.IS_TREASURY_ENABLED },
];

export const AccountDetailTabsMap: StringMap = {
  LOCAL: 'Domestic',
  INTERNATIONAL: 'International',
};

export const REQUEST_CURRENCY = 'Request Currency';
export const REQUEST_SENT = 'Request Sent!';

export const ACCOUNT_ACTIONS = {
  ADD_ACCOUNT: 'ADD_ACCOUNT',
};
