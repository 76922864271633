import { FLOW_PERMISSION_PROPERTIES } from 'constants/accessConstants';
import {
  ACCESS_HEADER_RESOURCE_ID_KEY,
  ACCESS_HEADER_RESOURCE_KEY,
  MFA_TOTP_KEY,
  PLATFORM_IDEMPOTENCY_HEADER_KEY,
} from 'constants/config';
import { APITags, REQUEST_TYPES } from 'constants/index';
import baseApi from 'services/api';
import {
  CreateVaultAccountPayloadType,
  EntityResponseType,
  PayoutsByAccountIdResponseType,
  PendingEntityAccountsResponseType,
  TreasuryByAccountIdResponseType,
  VaultCurrenciesResponseType,
  VaultsByEntityIdResponseType,
} from 'types/accountsApi.types';
import {
  AccountDetails,
  AddCurrencyPayload,
  CreateVaultPayload,
  EditVaultPayload,
  GetAllEntitiesAndAccountsResponse,
  GetValidVaultCurrenciesResponse,
  LinkNewAccountPayload,
  RequestCurrencyPayload,
  VaultResponse,
  VaultResponseById,
  WithdrawFunsPayloadType,
} from 'types/api';
import { Map } from 'types/catalog';
import { LinkAccoundDetailsResponseTypes } from 'types/investments.types';
import { accessBasedQueryWrapper } from 'utils/access';

const Accounts = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAccounDetails: builder.query<AccountDetails, void>({
      query: () => ({
        url: 'v1/b2b-user/account-details',
      }),
      transformResponse: ({ data }) => data,
    }),
    getLinkedAccounts: builder.query<LinkAccoundDetailsResponseTypes[], void>({
      query: () =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.MANAGE_TREASURY_WITHDRAWAL.permissionId, {
          url: 'v1/investment/account-payable-treasury',
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_TREASURY_WITHDRAWAL.scope,
          },
        }),
      transformResponse: ({ data }) => data,
    }),
    createPayableAccount: builder.mutation<Map, LinkNewAccountPayload>({
      query: ({ details, totp, idempotencyHeader }) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.MANAGE_TREASURY_WITHDRAWAL.permissionId, {
          url: 'v1/investment/add/account/payable/treasury',
          method: REQUEST_TYPES.POST,
          body: details,
          headers: {
            [PLATFORM_IDEMPOTENCY_HEADER_KEY]: idempotencyHeader,
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_TREASURY_WITHDRAWAL.scope,
            [MFA_TOTP_KEY]: totp,
          },
        }),
      transformResponse: ({ data }) => data,
    }),
    withDrawFundsWithMfa: builder.mutation<Map, WithdrawFunsPayloadType>({
      query: ({ payload, idempotencyHeader, headerKey }) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.MANAGE_TREASURY_WITHDRAWAL.permissionId, {
          url: 'v1/investment/transactions/withdrawl',
          method: REQUEST_TYPES.POST,
          body: payload,
          headers: {
            [PLATFORM_IDEMPOTENCY_HEADER_KEY]: idempotencyHeader,
            [MFA_TOTP_KEY]: headerKey,
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_TREASURY_WITHDRAWAL.scope,
          },
        }),
      transformResponse: ({ data }) => data,
    }),
    getVaultsByType: builder.query<VaultResponse[], string>({
      query: (type) => ({
        url: `v1/vaults/type/${type}`,
      }),
      transformResponse: ({ data }) => data,
      providesTags: [APITags.GET_VAULTS_BY_TYPE],
    }),
    getVaultsById: builder.query<VaultResponseById, string>({
      query: (id) => ({
        url: `v1/vaults/${id}`,
      }),
      transformResponse: ({ data }) => data,
    }),
    editVault: builder.mutation<VaultResponse, EditVaultPayload>({
      query: ({ body, idempotencyHeader }) => ({
        url: `v1/vaults/${body.id}`,
        method: REQUEST_TYPES.PATCH,
        body: body.data,
        headers: {
          [PLATFORM_IDEMPOTENCY_HEADER_KEY]: idempotencyHeader,
        },
      }),
      transformResponse: ({ data }) => data,
    }),
    addCurrencies: builder.mutation<void, AddCurrencyPayload>({
      query: ({ data, idempotencyHeader }) => ({
        url: 'v1/vaults/currency',
        method: REQUEST_TYPES.POST,
        body: data,
        headers: {
          [PLATFORM_IDEMPOTENCY_HEADER_KEY]: idempotencyHeader,
        },
      }),
    }),
    getValidVaultCurrencies: builder.query<GetValidVaultCurrenciesResponse, string>({
      query: (id) => ({
        url: `v1/vaults/${id}/currency`,
      }),
      transformResponse: ({ data }) => data,
    }),
    requestCurrency: builder.mutation<void, RequestCurrencyPayload>({
      query: ({ idempotencyHeader, data }) => ({
        url: 'v1/vaults/request/currency',
        method: REQUEST_TYPES.POST,
        body: data,
        headers: {
          [PLATFORM_IDEMPOTENCY_HEADER_KEY]: idempotencyHeader,
        },
      }),
    }),
    getAllEntitiesAndAccounts: builder.query<GetAllEntitiesAndAccountsResponse, void>({
      query: () => ({
        url: 'v1/vaults/accounts',
      }),
      transformResponse: ({ data }) => data,
    }),
    createVault: builder.mutation<VaultResponse, CreateVaultPayload>({
      query: ({ data, idempotencyHeader }) => ({
        url: 'v1/vaults/create',
        method: REQUEST_TYPES.POST,
        body: data,
        headers: {
          [PLATFORM_IDEMPOTENCY_HEADER_KEY]: idempotencyHeader,
        },
      }),
      transformResponse: ({ data }) => data,
      invalidatesTags: (_, error) => {
        if (!error) return [APITags.GET_VAULTS_BY_TYPE, APITags.GET_INTERNAL_TRANSFER_VAULTS];

        return [];
      },
    }),
    getAllEntities: builder.query<EntityResponseType[], void>({
      query: () =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.VIEW_MERCHANT_ACCOUNTS.permissionId, {
          url: 'v1/entities/',
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.VIEW_MERCHANT_ACCOUNTS.scope,
          },
        }),
      transformResponse: ({ data }) => data,
      providesTags: [APITags.GET_ALL_ENTITIES],
    }),
    getVaultsByEntityId: builder.query<VaultsByEntityIdResponseType, string>({
      query: (id) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.VIEW_ENTITY_ACCOUNTS.permissionId, {
          url: `v1/entities/${id}/vaults`,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.VIEW_ENTITY_ACCOUNTS.scope,
            [ACCESS_HEADER_RESOURCE_ID_KEY]: id,
          },
        }),
      transformResponse: ({ data }) => data,
    }),
    getVaultsByIdWithProducts: builder.query<VaultsByEntityIdResponseType, Record<string, string>>({
      query: ({ id, products }) => {
        return accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.MANAGE_TEAM.permissionId, {
          url: `v1/entities/${id}/vaults-minified?products=${products}`,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_TEAM.scope,
          },
        });
      },
      transformResponse: ({ data }) => data,
    }),
    getPayoutsByAccountId: builder.query<PayoutsByAccountIdResponseType, string>({
      query: (id) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.VIEW_ACCOUNT_DETAILS.permissionId, {
          url: `v1/accounts/payouts/${id}`,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.VIEW_ACCOUNT_DETAILS.scope,
            [ACCESS_HEADER_RESOURCE_ID_KEY]: id,
          },
        }),
      transformResponse: ({ data }) => data,
    }),
    getTreasuryByAccountId: builder.query<TreasuryByAccountIdResponseType, string>({
      query: (id) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.VIEW_ACCOUNT_DETAILS.permissionId, {
          url: `v1/accounts/treasury/${id}`,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.VIEW_ACCOUNT_DETAILS.scope,
            [ACCESS_HEADER_RESOURCE_ID_KEY]: id,
          },
        }),
      transformResponse: ({ data }) => data,
    }),
    getVaultCurrencies: builder.query<VaultCurrenciesResponseType, void>({
      query: () =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.CREATE_MERCHANT_ACCOUNTS.permissionId, {
          url: 'v2/vaults/currencies',
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.CREATE_MERCHANT_ACCOUNTS.scope,
          },
        }),
      transformResponse: ({ data }) => data,
    }),
    createVaultAccount: builder.mutation<VaultResponse, CreateVaultAccountPayloadType>({
      query: ({ data, idempotencyHeader }) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.CREATE_ACCOUNTS.permissionId, {
          url: 'v2/vaults/account',
          method: REQUEST_TYPES.POST,
          body: data,
          headers: {
            [PLATFORM_IDEMPOTENCY_HEADER_KEY]: idempotencyHeader,
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.CREATE_ACCOUNTS.scope,
            [ACCESS_HEADER_RESOURCE_ID_KEY]: data?.entity_id,
          },
        }),
      transformResponse: ({ data }) => data,
      invalidatesTags: (_, error) => {
        if (!error) return [APITags.GET_ALL_ENTITIES, APITags.GET_PENDING_ACCOUNTS, APITags.GET_USER_PERMISSIONS];

        return [];
      },
    }),
    getPendingAccounts: builder.query<PendingEntityAccountsResponseType[], void>({
      query: () =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.VIEW_MERCHANT_ACCOUNTS.permissionId, {
          url: 'v1/accounts/pending',
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.VIEW_MERCHANT_ACCOUNTS.scope,
          },
        }),
      transformResponse: ({ data }) => data,
      providesTags: [APITags.GET_PENDING_ACCOUNTS],
    }),
    getEntitiesFilterConfig: builder.query<PendingEntityAccountsResponseType[], void>({
      query: () =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.VIEW_MERCHANT_ACCOUNTS.permissionId, {
          url: 'v1/entities/filter-config',
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.VIEW_MERCHANT_ACCOUNTS.scope,
          },
        }),
      transformResponse: ({ data }) => data,
    }),
  }),
});

export const {
  useGetAccounDetailsQuery,
  useLazyGetAccounDetailsQuery,
  useGetLinkedAccountsQuery,
  useCreatePayableAccountMutation,
  useWithDrawFundsWithMfaMutation,
  useGetVaultsByIdQuery,
  useLazyGetVaultsByTypeQuery,
  useEditVaultMutation,
  useAddCurrenciesMutation,
  useGetValidVaultCurrenciesQuery,
  useRequestCurrencyMutation,
  useGetAllEntitiesAndAccountsQuery,
  useCreateVaultMutation,
  useGetAllEntitiesQuery,
  useGetVaultsByEntityIdQuery,
  useGetVaultsByIdWithProductsQuery,
  useGetPayoutsByAccountIdQuery,
  useGetTreasuryByAccountIdQuery,
  useLazyGetVaultCurrenciesQuery,
  useCreateVaultAccountMutation,
  useGetPendingAccountsQuery,
  useGetEntitiesFilterConfigQuery,
} = Accounts;
