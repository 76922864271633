import { FC, useEffect } from 'react';
import { defaultFnType } from 'types';

const INPUT_LIMIT = 6;

type InputProps = {
  value: string;
  className?: string;
  isDisabled?: boolean;
  regex?: RegExp;
  setOtp: (otp: string) => void;
  onBlur?: (evt: React.FocusEvent<HTMLInputElement>) => void;
  onEnter?: defaultFnType;
  autoFocus?: boolean;
  customStyles?: string;
  wrapperClassName?: string;
};

const OtpInput: FC<InputProps> = ({
  value,
  className,
  setOtp,
  onBlur,
  onEnter,
  isDisabled,
  regex,
  autoFocus = true,
  customStyles = null,
  wrapperClassName = null,
}) => {
  const onKeyUp = (event: any, index: number) => {
    const input = event?.key;
    const otpArr = value.split('');

    if (event.key === 'Enter') {
      event.preventDefault();
      if (onEnter) onEnter();
    } else if (input === 'ArrowLeft') {
      event.target.form.elements[Math.max(index - 1, 0)].focus();
    } else if (input === 'ArrowRight') {
      event.target.form.elements[Math.min(index + 1, value.length - 1)].focus();
    } else if (input === 'Delete' || input === 'Backspace') {
      if (otpArr[index] !== ' ') {
        otpArr[index] = ' ';
      } else if (index > 0) {
        otpArr[index - 1] = ' ';
        const firstEmptyIndex = Math.max(
          value.split('').findIndex((eachValue) => eachValue === ' '),
          1
        );

        event.target.form.elements[Math.min(index - 1, firstEmptyIndex)].focus();
      }
    } else {
      if (regex && !input.match(regex)) return;
      otpArr[index] = input;
      event.target.form.elements[Math.min(index + 1, INPUT_LIMIT - 1)].focus();
    }
    setOtp(otpArr.join(''));
  };

  const getClipboardContent = (event: any, index: number) => {
    try {
      let otp = value.substring(0, index);
      const pastedValue = event.clipboardData.getData('text/plain')?.trim() || '';

      if (regex && !pastedValue.match(regex)) return;

      otp = otp.trimEnd() + pastedValue;
      if (otp.length < INPUT_LIMIT) otp = otp + Array(INPUT_LIMIT - otp.length + 1).join(' ');
      else otp = otp.substring(0, INPUT_LIMIT);
      setOtp(otp);
      event.target.form.elements[Math.min(otp.trimEnd().length, INPUT_LIMIT - 1)].focus();
    } catch (e) {
      console.log(e);
    }
  };

  const inputElements = [];

  useEffect(() => {
    const initialValue = Array(INPUT_LIMIT + 1).join(' ');

    setOtp(initialValue);
  }, []);

  for (let index = 0; index < INPUT_LIMIT; index++)
    inputElements.push(
      <input
        id={'otp' + index}
        key={index}
        type='text'
        className={`${
          customStyles ?? 'tw-w-[58px] tw-h-[58px]'
        } tw-text-TEXT_GRAY_1 tw-text-center tw-rounded tw-border tw-border-DIVIDER_GRAY tw-disabled:opacity-60
         tw-bg-BASE_SECONDARY ${className}`}
        maxLength={1}
        onChange={(e) => e.preventDefault()}
        onBlur={onBlur}
        value={value[index] ?? ''}
        disabled={isDisabled}
        onFocus={(event) => {
          event.target.selectionStart = event.target.value.length;
          event.target.selectionEnd = event.target.value.length;
        }}
        onKeyUp={(e) => onKeyUp(e, index)}
        onPaste={(e) => getClipboardContent(e, index)}
        autoFocus={index === 0 && autoFocus}
      />
    );

  return (
    <form>
      <div className={`tw-flex ${wrapperClassName ?? 'tw-gap-2'}`}>{inputElements}</div>
    </form>
  );
};

export default OtpInput;
