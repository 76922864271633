import { CSSProperties, ReactNode } from 'react';
import { Button } from 'destiny/dist/components/molecules/button';
import { BUTTON_TYPES } from 'destiny/dist/constants';
import { MapAny } from 'types';
import { trackMixpanel } from 'utils/mixpanel';

interface BottomBarType {
  onNext: () => void;
  onBack: () => void;
  isNextDisabled?: boolean;
  isLoading?: boolean;
  nextBtnText?: string;
  btnStyle?: CSSProperties;
  hideBackButton?: boolean;
  submitButtonStyle?: string;
  backButtonTitle?: string;
  backButtonProps?: MapAny;
  nextButtonProps?: MapAny;
  className?: string;
  wrapperClassName?: string;
  nextButtonShowTrailingIcon?: boolean;
  nextButtonCustomTrailingIcon?: ReactNode;
  backButtonId?: string;
  nextButtonId?: string;
}

const BottomBar: React.FC<BottomBarType> = ({
  onNext,
  onBack,
  isNextDisabled = false,
  isLoading,
  nextBtnText,
  backButtonTitle = 'Back',
  hideBackButton,
  backButtonProps = {},
  nextButtonProps = {},
  className = '',
  wrapperClassName = '',
  nextButtonShowTrailingIcon = false,
  nextButtonCustomTrailingIcon,
  backButtonId = '',
  nextButtonId = '',
}) => {
  return (
    <div className={`tw-h-[90px] tw-px-6 tw-py-5 bottom-bar ${wrapperClassName}`}>
      <div className={`tw-flex tw-items-center tw-justify-end tw-gap-4 ${className}`}>
        {!hideBackButton && (
          <Button
            buttonProps={{
              btnType: BUTTON_TYPES.SECONDARY,
              id: backButtonId,
              eventCallback: trackMixpanel,
              onClick: onBack,
              ...backButtonProps,
            }}
          >
            {backButtonTitle}
          </Button>
        )}
        <Button
          buttonProps={{
            btnType: BUTTON_TYPES.PRIMARY,
            id: nextButtonId,
            eventCallback: trackMixpanel,
            onClick: onNext,
            disabled: isNextDisabled,
            isLoading: isLoading,
            ...nextButtonProps,
          }}
          showTrailingIcon={nextButtonShowTrailingIcon}
          customTrailingIcon={nextButtonCustomTrailingIcon}
        >
          {nextBtnText}
        </Button>
      </div>
    </div>
  );
};

export default BottomBar;
