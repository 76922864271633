export const TOTP_ERRORS = {
  INVALID_EMAIL_OTP: 'INVALID_EMAIL_OTP',
  INVALID_TOTP: 'INVALID_TOTP',
  TOTP_MFA_ALREADY_ENABLED: 'TOTP_MFA_ALREADY_ENABLED',
  FAILED_TO_INITIATE_TOTP_MFA: 'FAILED_TO_INITIATE_TOTP_MFA',
  TOTP_SETUP_INFO_NOT_FOUND: 'TOTP_SETUP_INFO_NOT_FOUND',
  TOTP_ALREADY_VALIDATED: 'TOTP_ALREADY_VALIDATED',
  TOTP_MAXIMUM_TRIES_REACHED: 'TOTP_MAXIMUM_TRIES_REACHED',
};

export const ATOMIC_TOTP_ERRORS = {
  MAX_ATTEMPTS_REACHED_FOR_MFA: 'MAX_ATTEMPTS_REACHED_FOR_MFA',
  MFA_EXPIRED: 'MFA_EXPIRED',
  INCORRECT_OTP: 'INCORRECT_OTP',
  ACTIVE_TRANSACTION_PRESENT_FOR_MERCHANT: 'ACTIVE_TRANSACTION_PRESENT_FOR_MERCHANT',
  FAILED_TO_RESEND_OTP: 'FAILED_TO_RESEND_OTP',
};

export const DEVICE_TYPES = {
  ANDROID: 'ANDROID',
  IOS: 'IOS',
};

export const VERIFICATION_ERROR_MESSAGE = 'Incorrect authentication code, Please try again';
export const DEFAULT_ERROR_MESSAGE = 'Something went wrong!';
